import React, { useState, useRef } from "react";
import "./InputOption.css";

const InputOption = ({ options, placeholder = "", onValueChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const containerRef = useRef(null);
  const divRef = useRef(null);

  const handleFocus = () => {
    setDropdownOpen(true);
    setFilteredOptions(options);
  };

  const handleBlur = () => {
    setTimeout(() => setDropdownOpen(false), 200);
  };

  const handleInputChange = (e) => {
    const value = divRef.current.textContent;
    //const value = e.target.textContent;
    setInputValue(value);

    if (onValueChange) {
      onValueChange(value);
    }

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    //setInputValue(option);
    divRef.current.textContent = option;
    setDropdownOpen(false);

    if (onValueChange) {
      onValueChange(option);
    }
  };

  return (
    <div
      className="input-select-container"
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={containerRef}
    >
      <div
        ref={divRef}
        key={"test"}
        className="input-select"
        contentEditable="true"
        role="textbox"
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
        onInput={handleInputChange}
        suppressContentEditableWarning={true}
        placeholder={placeholder}
      ></div>

      {isDropdownOpen && (
        <div className="options-list" role="listbox">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="option"
                role="option"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="option no-options"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default InputOption;
